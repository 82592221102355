import CountryList from "../locales/CountryListById";


    /**
     * Replace sender and receiver
     * @param str
     * @param senderId
     * @param receiverId
     * @returns {string|*}
     */
export function replaceCounties({str, senderId, receiverId}) {
        if (!str) return '';
        return str
            .replace(/{senderId.f\d..}/gi, (str) => {
                let form = str.replace(/{|}/gi, '').split('.')[1];
                return CountryList[senderId][form]
            })
            .replace(/{receiverId.f\d..}/gi, (str) => {
                let form = str.replace(/{|}/gi, '').split('.')[1];
                return CountryList[receiverId][form];
            });
    }
