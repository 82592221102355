import React from 'react';
import axios from 'axios'
import {useTranslation} from "react-i18next";
import styles from './trustbox.module.scss'

const BUSINESS_ID =  '5c946f0a7f40060001b51dba';

const getLocaleByLang = {
    en: 'en-UK',
    ru: 'ru-RU'
};

const TrustBox = () => {
    const {i18n} = useTranslation();
    const ref = React.useRef(null);
    React.useEffect(async () => {
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(ref.current, true);
        }
    }, []);
    return (
        <div className={styles['wrapper']}>
            <div
                ref={ref}
                className="trustpilot_widget"
                data-locale="en-UK"
                data-template-id="53aa8912dec7e10d38f59f36"
                data-businessunit-id="5c946f0a7f40060001b51dba"
                data-style-height="140px"
                data-style-width="100%"
                data-theme="light"
                data-stars="4,5"
                data-review-languages={i18n.language}
            >
                <a href={`https://uk.trustpilot.com/review/koronapay.com`} target="_blank" rel="noopener"> Trustpilot</a>
            </div>
        </div>

    );
};
export default TrustBox;
