export const resolveNewInfoItem = (
  tariffsInfoData: TransferTariffInfo[],
  sendingCurrencyId: string | undefined,
  receivingCurrencyId: string | undefined
) => {
  const itemWithBothCurrentCurrencies = tariffsInfoData.find(
    (info: TransferTariffInfo) => info.sendingCurrency.id === sendingCurrencyId && info.receivingCurrency.id === receivingCurrencyId
  );
  if (itemWithBothCurrentCurrencies) return { newInfoItem: itemWithBothCurrentCurrencies };

  const itemWithCurrentSendingCurrency = tariffsInfoData.find(
    (info: TransferTariffInfo) => info.sendingCurrency.id === sendingCurrencyId
  );
  if (itemWithCurrentSendingCurrency) return { newInfoItem: itemWithCurrentSendingCurrency };

  const itemWithCurrentReceivingCurrency = tariffsInfoData.find(
    (info: TransferTariffInfo) => info.receivingCurrency.id === receivingCurrencyId
  );
  if (itemWithCurrentReceivingCurrency) return { newInfoItem: itemWithCurrentReceivingCurrency };

  return { newInfoItem: tariffsInfoData[0] };
};
