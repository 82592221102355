import { replaceCounties } from '../../../../src/services/countryNaming';

export const getArticleMarkup = (data: any, url: string) => {
  const { title, keywords, published_at, created_at, updated_at, description, slug } = data;
  return {
    '@context': 'https://schema.org',
    '@type': 'Article',
    headline: title,
    keywords: `${keywords || ''}`,
    publisher: {
      '@type': 'Organization',
      name: 'KoronaPay'
    },
    url: `${url}blog/${slug}`,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `/`
    },
    datePublished: published_at,
    dateCreated: created_at,
    dateModified: updated_at,
    description
  };
};

export const getFAQMarkup = (data: any[], receiverId: string, senderId: string) => ({
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  mainEntity: data.map((question) => ({
    '@type': 'Question',
    name: replaceCounties({ str: question.title, receiverId, senderId }),
    acceptedAnswer: {
      '@type': 'Answer',
      text: replaceCounties({ str: question.content, receiverId, senderId })
    }
  }))
});

export const getBreadcrumbMarkup = (landing: string, name: string, url: string) => {
  if (url.includes('transfers/europe')) {
    return {
      '@context': 'https://schema.org/',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          name: 'KoronaPay',
          item: 'https://koronapay.com/'
        },
        {
          '@type': 'ListItem',
          position: 2,
          name: 'Денежные переводы',
          item: 'https://koronapay.com/transfers/'
        },
        {
          '@type': 'ListItem',
          position: 3,
          name: 'Денежные переводы из Европы',
          item: 'https://koronapay.com/transfers/europe/'
        },
        {
          '@type': 'ListItem',
          position: 4,
          name,
          item: `https://koronapay.com/transfers/europe/landing/${landing}/`
        }
      ]
    };
  }
  return {
    '@context': 'https://schema.org/',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'KoronaPay',
        item: 'https://koronapay.eu/'
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: 'Денежные переводы из Польши в Украину',
        item: `https://koronapay.eu/landing/${landing}/`
      }
    ]
  };
};
