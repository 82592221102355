import convertLayout from 'convert-layout';

export const REGEX = {
  FULL_LATIN: "[0-9A-Za-z`#\\-\\_/|\\\\,.:;'/\\s-]"
};

export const includesByDifLangLayout = (str: string, subStr: string, locale: string): boolean => {
  const isLatin = new RegExp(`^${REGEX.FULL_LATIN}*$`, 'i').test(subStr);
  if (locale === 'en' && isLatin) {
    return str.toLowerCase().includes(subStr.toLowerCase());
  }
  if (locale !== 'en' && isLatin) {
    return convertLayout.ru
      .fromEn(str.toLowerCase())
      .includes(convertLayout.ru.fromEn(subStr.toLowerCase()));
  }
  return convertLayout.ru
    .toEn(str.toLowerCase())
    .includes(convertLayout.ru.toEn(subStr.toLowerCase()));
};
