import { DEFAULT_MIN_AMOUNT } from '@utils/constants';
import { multiplyByHundred } from '@utils/helpers';

interface ValidateAmountLimitParams {
  amount: number;
  amountKey: 'sendingAmount' | 'receivingAmount';
  currentInfoItem: TransferTariffInfo;
}

export const validateAmountLimit = ({ amountKey, amount, currentInfoItem }: ValidateAmountLimitParams) => {
  if (amountKey === 'sendingAmount') {
    return (
      currentInfoItem.fractionalReceivingAmountUsage !== 'enabled'
      || (amount <= currentInfoItem.maxSendingAmount && amount >= Math.max(multiplyByHundred(DEFAULT_MIN_AMOUNT), currentInfoItem.minSendingAmount))
    );
  }
  if (amountKey === 'receivingAmount') {
    return (
      amount <= currentInfoItem.maxReceivingAmount
      && amount >= Math.max(multiplyByHundred(DEFAULT_MIN_AMOUNT), currentInfoItem.minReceivingAmount)
    );
  }
  throw new Error(`Invalid 'amountKey' in 'validateAmountLimit': ${amountKey}`);
};
